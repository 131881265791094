<template>
    <div class="courseAI">
        <div class="title flex-align-around">课堂AI分析报告</div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="教师综合能力分析" name="first">
                <img src="../assets/images/course_report/01.png" alt="">
            </el-tab-pane>
            <el-tab-pane label="课堂教学行为分析" name="second">
                <img src="../assets/images/course_report/02.png" alt="">
            </el-tab-pane>
            <el-tab-pane label="课堂思维培养分析" name="third">
                <img src="../assets/images/course_report/03.png" alt="">
            </el-tab-pane>
            <el-tab-pane label="学生学习行为分析" name="fourth">
                <img src="../assets/images/course_report/04.png" alt="">
            </el-tab-pane>
            <el-tab-pane label="教学设计执行度分析" name="fifty">
                <img src="../assets/images/course_report/05.png" alt="">
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeName: 'first'
        };
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        }
    }
};
</script>
  
<style lang="less" scoped>
.courseAI {
    .title {
        height: 45px;
        background-color: #333;
        color: #fff;
        font-size: 18px;
    }

    img {
        width: 100%;
    }
}
</style>